<template>
  <div class="vd_button_group">
    <span v-if="isShow === true">
      <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
    </span>
    <span v-if="isShow === false">
      <el-button type="primary" @click="submit(strForm)" size="small">保存</el-button>
      <el-button @click="closeEdit" size="small">取消</el-button>
    </span>
    <span class="vg_tag_position" v-if="staFlag">
      <span class="vg_tag_label">状态：</span>
      <el-tag :type="helper.getStatusName(strForm.status).type">{{ helper.getStatusName(strForm.status).name }}</el-tag>
    </span>
    <span class="vg_tag_position" v-if="customFlag">
      <span class="vg_tag_label">状态：</span>
      <el-tag :type="custom_status.type">{{ custom_status.name }}</el-tag>
    </span>
  </div>
</template>

<script>
export default {
  name: 'editHeader',
  props: {
    isShow: Boolean,
    btn: Object,
    strForm: Object,
    staFlag: {
      type: Boolean,
      default: true
    },
    customFlag: {
      type: Boolean,
      default: false
    },
    custom_status: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      stateFlag: true
    };
  },
  watch: {
    strForm(newValue, oldValue) {
      console.log(newValue, oldValue);
    }
  },
  mounted() {
    if (this.$route.query.rework === 'true') {
      this.openEdit();
    }
  },
  methods: {
    openEdit() {
      this.$emit('openEdit');
    },
    closeEdit() {
      this.$emit('closeEdit');
    },
    submit(strForm) {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped></style>
